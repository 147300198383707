import { Route } from '@angular/router';
import { MAIN_SLUGS } from '@routes/routes.types';

export const INSIGHTS_SLUGS = {
    conversations: 'conversations',
    responses: 'responses',
    assignments: 'assignments',
    products: 'products',
} as const;

export const INSIGHTS_ROUTE: Route = {
    path: MAIN_SLUGS.INSIGHTS,
    title: 'Insights',
    loadComponent: () =>
        import('@features/insights/insights.component').then(m => m.InsightsComponent),
    children: [
        {
            path: '',
            pathMatch: 'full',
            redirectTo: INSIGHTS_SLUGS.conversations,
        },
        {
            path: INSIGHTS_SLUGS.conversations,
            title: 'Conversations',
            loadComponent: () =>
                import('@features/insights/components/conversations/conversations.component').then(
                    m => m.ConversationsComponent
                ),
        },
        {
            path: INSIGHTS_SLUGS.responses,
            title: 'Responses',
            loadComponent: () =>
                import('@features/insights/components/responses/responses.component').then(
                    m => m.ResponsesComponent
                ),
        },
        {
            path: INSIGHTS_SLUGS.assignments,
            title: 'Assignments',
            loadComponent: () =>
                import('@features/insights/components/assignments/assignments.component').then(
                    m => m.AssignmentsComponent
                ),
        },
        /*     {
            path: INSIGHTS_SLUGS.products,
            title: 'Products',
            loadComponent: () =>
                import('@features/insights/components/products/products.component').then(
                    m => m.ProductsComponent
                ),
        }, */
        {
            path: '**',
            pathMatch: 'full',
            redirectTo: INSIGHTS_SLUGS.conversations,
        },
    ],
};
